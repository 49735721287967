import React from 'react';
import { Modal, Button } from 'antd';

const AppDownloadModal = ({isModalVisible, setIsModalVisible,showInstructions, setShowInstructions, downloadAPK}) => {
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [showInstructions, setShowInstructions] = useState(false);

//   const openModal = () => {
//     setIsModalVisible(true);
//   };

  const handleDownload = () => {
    // Start the APK download
    const downloadLink = document.createElement('a');
    // downloadLink.href = 'https://yourwebsite.com/path/to/your-app.apk'; // Update this link
    downloadLink.href = downloadAPK.link; // Update this link
    // downloadLink.download = 'Rapost.apk';
    downloadLink.download = downloadAPK.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Show installation instructions
    setShowInstructions(true);
  };

  return (
    <>
      {/* Button to open modal */}
      {/* <Button type="primary" onClick={openModal}>
        Download from PlayStore
      </Button> */}

      {/* Modal Component */}
      <Modal
        visible={isModalVisible}
        footer={null}
        closable={false}
        centered
        bodyStyle={{
          textAlign: 'center',
          padding: '20px',
          fontSize: '16px',
        }}
      >
        {!showInstructions ? (
          <>
            <h3>There is a problem reaching PlayStore.</h3>
            <p>Download APK file instead?</p>
            <div style={{ marginTop: '20px' }}>
              <Button style={{background:'#007bff', color:'white'}} onClick={handleDownload}>
                Yes, Download
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => setIsModalVisible(false)}
              >
                No, Cancel
              </Button>
            </div>
          </>
        ) : (
          <div style={{ textAlign: 'left' }}>
            <h3>How to Install the APK File on Android</h3>
            <ol>
              <li>Locate the downloaded APK file.</li>
              {/* <li>Locate the downloaded APK file (named {downloadAPK.name}).</li> */}
              <li>
                Open <b>Settings</b> on your Android device.
              </li>
              <li>
                Navigate to <b>Security</b> (or <b>Apps & Notifications</b>).
              </li>
              <li>
                Enable <b>Install unknown apps</b> or <b>Allow from this source</b> for your browser.
              </li>
              <li>
                Go back and tap the APK file to start installation.
              </li>
              <li>Follow any on-screen prompts to finish installation.</li>
            </ol>
            <p style={{ fontStyle: 'italic' }}>
              Note: Enabling "Install unknown apps" may vary slightly depending
              on your Android version.
            </p>
            <Button
              type="default"
              onClick={() => setIsModalVisible(false)}
              style={{ marginTop: '20px' }}
            >
              Close Instructions
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AppDownloadModal;
