import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Topbars.css';
// import ProgressiveImage from '../common/ProgressiveImage';
// import { useSelector } from 'react-redux';
import { Image } from 'antd';
import { FaBars, FaTimes } from 'react-icons/fa';
import { logo } from '../assets/assets';

const TopNavbar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const location = useLocation();
    const [shouldHideLogo, setShouldHideLogo] = useState(false);


    const menuItem = [
        { path: "/", name: "Home" },
        { path: "/about", name: "About Us" },
        { path: "/what-we-do", name: "What We Do" },
        { path: "/projects", name: "Projects" },
        { path: "/staff", name: "Our Team" },
        { path: "/contact", name: "Contact Us" }
    ];

    useEffect(() => {
        const updateLogoVisibility = () => {
            if (location.pathname === "/" && window.innerWidth >= 1024) {
                setShouldHideLogo(true);
            } else {
                setShouldHideLogo(false);
            }
        };

        updateLogoVisibility();
        window.addEventListener('resize', updateLogoVisibility);
        return () => window.removeEventListener('resize', updateLogoVisibility);
    }, [location.pathname]);

    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };

    return (
        <div className='container'>
            <div className='topnav'>
                <div className={`logo-img logo ${shouldHideLogo ? 'hide-logo' : 'show-logo'}`}>
                    <Image
                        src={logo}
                        // src={pictures?.company ? pictures?.company?.logo : logo}
                        alt="logo"
                        className='logo-img'
                        preview={false}
                    />
                    {/* <h3>TANZATOR</h3> */}
                </div>
                <div className={`${isOpen ? 'closeBars' : 'bars'}`} onClick={toggle} >
                    {/* <span className='hamburger-menu'>☰</span> */}
                    {isOpen ?
                            <FaTimes />
                            :
                            <FaBars />
                        }
                </div>
                <div className={`menu ${isOpen ? 'open' : ''} ${shouldHideLogo ? 'centered' : ''}`}>
                    {menuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className='link' activeClassName='active' onClick={handleLinkClick} >
                            <div className='link_text'>{item.name}</div>
                        </NavLink>
                    ))}
                </div>
            </div>
            <main>{children}</main>
        </div>
    );
}

export default TopNavbar;
