import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Projects.css';
import { FaGlobe, FaApple, FaGooglePlay } from 'react-icons/fa';
// import tanzart1 from '../../assets/pictures/tanzart1.jpg';
// import tanzart2 from '../../assets/pictures/tanzart2.jpg';
// import rapost1 from '../../assets/pictures/rapost1.jpg';
// import rapost2 from '../../assets/pictures/rapost2.jpg';
// import ngnantranga1 from '../../assets/pictures/ngnantranga1.png';
// import ngnantranga2 from '../../assets/pictures/ngnantranga2.png';
// import yatch1 from '../../assets/pictures/yatch1.png';
// import yatch2 from '../../assets/pictures/yatch2.png';
// import yatch3 from '../../assets/pictures/yatch3.png';
// import yatch4 from '../../assets/pictures/yatch4.png';
// import yatch5 from '../../assets/pictures/yatch5.png';
import { matrixTextEffect } from '../../utils';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import ProgressiveImage from '../common/ProgressiveImage';
import { notification } from 'antd';
import AppDownloadModal from './AppDownloadModal';

const projectsData = [
    {
        id: 1,
        name: "Tanzart",
        type: "Mobile App",
        description: "An innovative app crafted for art lovers, this platform provides an immersive space to explore and create a wide variety of digital art forms. Whether you're into poetry, songwriting, storytelling, digital illustration, sketching, acting, or other creative pursuits, this app offers the tools and community you need to express your artistic vision.",
        technologies: ["React Native", "Node.js", "MongoDB"],
        status: "Completed",
        startDate: "September 2023",
        endDate: "Ongoing",
        // imageUrl: [tanzart1, tanzart2],
        previewLinks: {
            website: "https://www.tanzart.art",
            appStore: "",
            playStore: "pending"
        },
        isLinkReady: {
            website: true,
            appStore: false,
            playStore: false
        },
        downloadAPK: {
            link: "link",
            name: "Tanzart.apk"
        }
    },
    {
        id: 2,
        name: "RAPOST - Rapid Online Store",
        type: "Mobile App",
        description: "Rapost is your trusted e-commerce platform for buying, exchanging, or requesting new, used, and refurbished products and services. With vetted sellers, strict quality checks, safe transactions, and a user-friendly interface, RAPOST ensures a seamless, reliable, and secure shopping experience tailored to your needs.",
        technologies: ["React", "Firebase"],
        status: "In Progress",
        startDate: "February 2024",
        endDate: "June 2024",
        // imageUrl: [rapost1, rapost2],
        previewLinks: {
            website: "https://www.rapost.store",
            appStore: "pending",
            playStore: "https://play.google.com/store/apps/details?id=store.rapost.app"
        },
        isLinkReady: {
            website: true,
            appStore: false,
            playStore: false
        },
        downloadAPK: {
            link: "https://expo.dev/artifacts/eas/wk9WwncTRTjFxjcjzNfPz3.apk",
            name: "Rapost.apk"
        }
    },
    {
        id: 3,
        name: "Yacht Cruise Connect",
        type: "Web App",
        description: "Yacht Cruise Connect is a comprehensive platform designed to simplify the process of booking yacht cruises, offering seamless navigation and an intuitive interface that makes it easy for users to explore various options and make reservations. Beyond standard cruises, we also create custom events tailored specifically to your preferences.",
        technologies: ["React", "Node.js", "PostgreSQL"],
        status: "Completed",
        startDate: "June 2024",
        endDate: "June 2024",
        // imageUrl: [yatch1, yatch2, yatch3, yatch4, yatch5],
        previewLinks: {
            website: "https://www.yatchcruiseconnect.com",
            appStore: null,
            playStore: null
        }
    },
    {
        id: 4,
        name: "Ngnantranga",
        type: "Web App",
        description: "A website for Ngnantranga Food Hub Production; A pioneering enterprise based in Anjouan, Comoros, committed to addressing food insecurity and promoting sustainable development",
        technologies: ["Angular", "Java", "MySQL"],
        status: "Completed",
        startDate: "June 2024",
        endDate: "June 2024",
        // imageUrl: [ngnantranga1, ngnantranga2],
        previewLinks: {
            website: "https://www.ngnantranga.com",
            appStore: null,
            playStore: null
        }
    }
];


const handleAppStoreClick = () => {
    notification.info({
        message: 'Coming Soon!',
        description: 'The app will be available in the App Store soon. Stay tuned!',
        placement: 'topRight',
        duration: 5,
    });
};
const handlePlayStoreClick = () => {
    notification.info({
        message: 'Coming Soon!',
        description: 'The app will be available in the Google Play Store soon. Stay tuned!',
        placement: 'topRight',
        duration: 5,
    });
};

const Projects = () => {
    const pictures = useSelector(state => state.launch.pictures);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [downloadAPK, setDownloadAPK] = useState({});
    const [showInstructions, setShowInstructions] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        const textElement = document.querySelector(".matrix-effect");
        const text = "Transformative projects that set new benchmarks. Discover how we've revolutionized industries and driven growth. Click a project to see more of it!";
        matrixTextEffect(textElement, text);

    }, []);

    const handleDownloadAPK = (downloadAPK) => {
        setDownloadAPK(downloadAPK);
        setShowInstructions(false);
        setIsModalVisible(true);
    };

    return (
        <div className="projects-container">
            <Helmet>
                <title>Our Projects - Tanzator</title>
                <meta name="description" content="Explore the impactful projects undertaken by Tanzator in the local and international markets." />
                <meta name="keywords" content="Tanzator, projects, innovative solutions, technology, Tanzania, website, software, develop, company, app, application" />
                <meta property="og:title" content="Our Projects - Tanzator" />
                <meta property="og:description" content="Explore the impactful projects undertaken by Tanzator in the local and international markets." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.tanzator.com/projects" />
                <meta property="og:image" content="https://www.tanzator.com/logo192.png" />
            </Helmet>
            <section className="about-hero">
                <h1 className="projects-title">Our Projects</h1>
                <p className="matrix-effect">Transformative projects that set new benchmarks. Discover how we've revolutionized industries and driven growth. Click a project to see more of it!</p>
            </section>
            <div className="projects-list">
                {projectsData.map((project) => (
                    <div className="project-card" key={project.id}>
                        <Link to={`/projects/${project.id}`} className="project-link">
                            <ProgressiveImage
                                src={pictures?.projects?.[project.id]?.[0]}
                                alt={project.name}
                                className="project-image"
                            />
                            <div className="project-details">
                                <h2 className="project-name">{project.name}</h2>
                                {/* <p className="project-type">{project.type}</p> */}
                                <p className="project-description"><span className="project-description-clamped">{project.description}</span><span className="see-more-span">see more</span></p>

                            </div>
                        </Link>
                        <div className="project-actions">
                            {project.previewLinks.website && (
                                <a href={project.previewLinks.website} target="_blank" rel="noopener noreferrer" className="preview-link">
                                    <FaGlobe className="icon" /> <span className="main-text">Visit Website</span>
                                </a>
                            )}
                            {project.previewLinks.appStore && project.previewLinks.appStore === 'pending' && (
                                <div onClick={handleAppStoreClick} className="preview-link" title="appStore">
                                    <FaApple className="icon" />
                                    <span>
                                        <span className="small-text">Download on the</span>
                                        <span className="main-text">App Store</span>
                                    </span>
                                </div>
                            )}
                            {project.previewLinks.appStore && project.previewLinks.appStore !== 'pending' && (
                                <a href={project.previewLinks.appStore} target="_blank" rel="noopener noreferrer" className="preview-link" title="appStore">
                                    <FaApple className="icon" />
                                    <span>
                                        <span className="small-text">Download on the</span>
                                        <span className="main-text">App Store</span>
                                    </span>
                                </a>
                            )}
                            
                            {project.previewLinks.playStore && project.previewLinks.playStore === 'pending' && (
                                <div onClick={handlePlayStoreClick} className="preview-link" title="playStore">
                                    <FaGooglePlay className="icon" />
                                    <span>
                                        <span className="small-text">Get it on</span>
                                        <span className="main-text">Google Play</span>
                                    </span>
                                </div>
                            )}

                            {project.previewLinks.playStore && project.previewLinks.playStore !== 'pending' && (
                                <div onClick={() => handleDownloadAPK(project?.downloadAPK)} className="preview-link" title="playStore">
                                    <FaGooglePlay className="icon" />
                                    <span>
                                        <span className="small-text">Get it on</span>
                                        <span className="main-text">Google Play</span>
                                    </span>
                                </div>
                            )}

                            {project.previewLinks.playStore && project.isLinkReady.playStore && project.previewLinks.playStore !== 'pending' && (
                                <a href={project.previewLinks.playStore} target="_blank" rel="noopener noreferrer" className="preview-link" title="playStore">
                                    <FaGooglePlay className="icon" />
                                    <span>
                                        <span className="small-text">Get it on</span>
                                        <span className="main-text">Google Play</span>
                                    </span>
                                </a>
                            )}
                        </div>

                    </div>
                ))}
            </div>
            <AppDownloadModal downloadAPK={downloadAPK} showInstructions={showInstructions} setShowInstructions={setShowInstructions} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} key={downloadAPK.link} />

        </div>
    );
}

export default Projects;
