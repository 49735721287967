// ServiceReducer.js

import { PROCESS_SERVICE, PROCESS_SERVICE_FAILURE, PROCESS_SERVICE_SUCCESS } from "../actions/Types";

const initialState = {
    loading: false,
    success: false,
    error: null
  };
  
  const ServiceReducer = (state = initialState, action) => {
    switch (action.type) {
      case PROCESS_SERVICE:
        return {
          ...state,
          loading: true,
          success: false,
          error: null
        };
  
      case PROCESS_SERVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          error: null
        };
  
      case PROCESS_SERVICE_FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload
        };
  
      default:
        return state;
    }
  };
  
  export default ServiceReducer;
  