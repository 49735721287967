import { FETCH_PICTURES, FETCH_PICTURES_FAILURE, FETCH_PICTURES_SUCCESS } from "../actions/Types";


const initialState = {
    loading: false,
    pictures: {},
    error: null,
};

const LaunchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PICTURES:
            return { ...state, loading: true, error: null };
        case FETCH_PICTURES_SUCCESS:
            return { ...state, loading: false, pictures: action.payload };
        case FETCH_PICTURES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default LaunchReducer;
