// utils/matrixTextEffect.js
export const matrixTextEffect = (element, text) => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let interval = null;
    let iteration = 0;

    clearInterval(interval);

    interval = setInterval(() => {
        element.innerText = element.innerText
            .split("")
            .map((letter, index) => {
                if (index < iteration) {
                    return text[index];
                }

                return letters[Math.floor(Math.random() * 26)];
            })
            .join("");

        if (iteration >= text.length) {
            clearInterval(interval);
        }

        iteration += 1 / 3;
    }, 1);
};



export function applyMatrixEffect(selector) {
    const element = document.querySelector(selector);
    if (!element) return;

    const originalText = element.innerText;
    element.innerHTML = '';

    let index = 0;
    const typingSpeed = 100;

    function type() {
        if (index < originalText.length) {
            element.innerHTML += originalText.charAt(index);
            index++;
            setTimeout(type, typingSpeed);
        } else {
            blink();
        }
    }

    function blink() {
        element.classList.add('blink-caret');
    }

    type();
}
