// fetchPictures.js
import { collection, getDocs } from "firebase/firestore";
import { FETCH_PICTURES, FETCH_PICTURES_FAILURE, FETCH_PICTURES_SUCCESS } from "./Types";
import { db } from "../App";

export const fetchPictures = () => async (dispatch) => {
    dispatch({ type: FETCH_PICTURES });
    // console.log('fetching pictures')

    try {
        const picturesCollection = collection(db, 'pictures');
        const pictureSnapshot = await getDocs(picturesCollection);
        const pictureObject = pictureSnapshot.docs.reduce((acc, doc) => {
            acc[doc.id] = doc.data();
            return acc;
          }, {});

        //   console.log(pictureObject)
        dispatch({ type: FETCH_PICTURES_SUCCESS, payload: pictureObject });
    } catch (error) {
        // console.log('failed')
        dispatch({ type: FETCH_PICTURES_FAILURE, payload: error.message });
    }
};
