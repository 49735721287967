import {
    AUTH_FIELD_CHANGED,
    LOGIN_USER,
    LOGIN_USER_FAILED,
    LOGIN_USER_SUCCESSFUL,
    LOGOUT_USER,
    LOGOUT_USER_FAILED,
    LOGOUT_USER_SUCCESSFUL,
    REGISTER_USER,
    REGISTER_USER_FAILED,
    REGISTER_USER_SUCCESSFUL
} from "../actions/Types";

const INITIAL_STATE = {
    email: "",
    password: "",
    email2: "",
    password2: "",
    ConfirmPassword: "",
    loginLoading: false,
    loginError: '',
    logoutLoading: false,
    registerloading: false,
    myProfile: null
}

const AuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_FIELD_CHANGED:
            // console.log({prop: action.payload.prop, value: action.payload. value});
            return { ...state, [action.payload.prop]: action.payload.value };

        case LOGIN_USER:
            return { ...state, loginLoading: true, user: null };
        case LOGIN_USER_SUCCESSFUL:
            return { ...state, loginLoading: false, user: action.payload };
        case LOGIN_USER_FAILED:
            return { ...state, loginLoading: false, loginError: action.payload };

        case REGISTER_USER:
            return { ...state, registerloading: true };
        case REGISTER_USER_SUCCESSFUL:
            return { ...state, registerloading: false };
        case REGISTER_USER_FAILED:
            return { ...state, registerloading: false };

        case LOGOUT_USER:
            return { ...state, logoutLoading: true };
        case LOGOUT_USER_SUCCESSFUL:
            return { ...state, logoutLoading: false };
        case LOGOUT_USER_FAILED:
            return { ...state, logoutLoading: false };

        default:
            return state;
    }
};

export default AuthReducer;