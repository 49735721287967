import React, { useState } from 'react';
import { Image, Spin } from 'antd';
// import placeholderImg from '../../assets/pictures/placeholder.png';
// import placeholderImgH from '../../assets/pictures/placeholderH.png';
// import placeholderImgV from '../../assets/pictures/placeholderV.png';
import placeholderImg from '../../assets/errorImage.png';
import placeholderImgH from '../../assets/errorImage.png';
import placeholderImgV from '../../assets/errorImage.png';
import './ProgressiveImage.css';

const ProgressiveImage = ({ src, alt, className }) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const handleLoadStart = () => {
        setLoaded(false);
    };

    const handleLoadEnd = () => {
        setLoaded(true);
    };

    const handleError = () => {
        setError(true);
    };

    const getPlaceholder = () => {
        switch (className) {
            case 'project-image':
            case 'project-details-image':
            case 'home-project-image':
                return placeholderImgH;
            case 'team-member-image':
            case 'profile-image':
                return placeholderImgV;
            default:
                return placeholderImg;
        }
    };

    const containerStyle = className === 'project-image' || className === 'project-details-image' || className === 'home-project-image'
        ? { aspectRatio: '16/9' }
        : className === 'team-member-image'
            ? { aspectRatio: '1' }
            : className === 'profile-image'
                ? { aspectRatio: '4/5' }
                : {};

    return (
        <div className={`progressive-image-container ${className}`} style={containerStyle}>
            {!loaded && (
                <div className="spinner-container">
                    <Spin size="large" />
                </div>
            )}

            <Image
                src={error ? getPlaceholder() : src}
                alt={alt}
                onLoadStart={handleLoadStart}
                onLoad={handleLoadEnd}
                onError={handleError}
                className={`progressive-image ${loaded ? 'loaded' : ''}`}
                preview={className !== 'team-member-image' && className !== 'project-image' && !error}
            />
        </div>
    );
};

export default ProgressiveImage;
