import {combineReducers} from "redux";

//import reducers
import AuthReducer from "./AuthReducer";
import AnalyticsReducer from "./AnalyticsReducer";
import ContactReducer from "./ContactReducer";
import ServiceReducer from "./ServiceReducer";
import LaunchReducer from "./LaunchReducer";
import SaasReducer from "./SaasReducer";

export default combineReducers({
    auth: AuthReducer,
    analytics: AnalyticsReducer,
    contact: ContactReducer,
    service: ServiceReducer,
    launch: LaunchReducer,
    saas: SaasReducer,
});