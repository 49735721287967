import {
  ADD_EMAIL,
  ADD_EMAIL_FAILURE,
  ADD_EMAIL_SUCCESS,
  CREATE_MESSAGE,
  CREATE_MESSAGE_FAILURE,
  CREATE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_NEWSLETTER_FAILURE,
  DELETE_NEWSLETTER_REQUEST,
  DELETE_NEWSLETTER_SUCCESS,
  FETCH_ALL_MESSAGES_FAILURE,
  FETCH_ALL_MESSAGES_REQUEST,
  FETCH_ALL_MESSAGES_SUCCESS,
  FETCH_ALL_NEWSLETTERS_FAILURE,
  FETCH_ALL_NEWSLETTERS_REQUEST,
  FETCH_ALL_NEWSLETTERS_SUCCESS
} from "../actions/Types";

const initialState = {
  error: null,
  allNewsletterEmails: [],
  allMessages: [],
};

const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMAIL:
      return { ...state, error: null };
    case ADD_EMAIL_SUCCESS:
      return { ...state, error: null };
    case ADD_EMAIL_FAILURE:
      return { ...state, error: null };


    case FETCH_ALL_NEWSLETTERS_REQUEST:
      return { ...state, error: null };
    case FETCH_ALL_NEWSLETTERS_SUCCESS:
      return { ...state, allNewsletterEmails: action.payload };
    case FETCH_ALL_NEWSLETTERS_FAILURE:
      return { ...state, error: null };


    case DELETE_NEWSLETTER_REQUEST:
      return { ...state, error: null };
    case DELETE_NEWSLETTER_SUCCESS:
      return { ...state, error: null };
    case DELETE_NEWSLETTER_FAILURE:
      return { ...state, error: null };


    case CREATE_MESSAGE:
      return { ...state, error: null };
    case CREATE_MESSAGE_SUCCESS:
      return { ...state, error: null };
    case CREATE_MESSAGE_FAILURE:
      return { ...state, error: null };


    case FETCH_ALL_MESSAGES_REQUEST:
      return { ...state, error: null };
    case FETCH_ALL_MESSAGES_SUCCESS:
      return { ...state, allMessages: action.payload };
    case FETCH_ALL_MESSAGES_FAILURE:
      return { ...state, error: null };


    case DELETE_MESSAGE_REQUEST:
      return { ...state, error: null };
    case DELETE_MESSAGE_SUCCESS:
      return { ...state, error: null };
    case DELETE_MESSAGE_FAILURE:
      return { ...state, error: null };


    default:
      return state;
  }
};

export default ContactReducer;
