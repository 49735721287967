import React, { lazy, Suspense, useLayoutEffect, useState } from 'react';
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import _ from 'lodash';
// import { Spinner } from "./components/common";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { fetchPictures } from './actions/LaunchActions';

//file imports
import './App.css';
import './components/home/Home.css';
import './components/projects/Projects';


// import Login from './components/launch/Login';
import Topbars from './components/navigation/Topbars';
// import Home from './components/home/Home';
// import About from './components/about/About';
// import Projects from './components/projects/Projects';
// import Staff from './components/staff/Staff';
// import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
// import Services from './components/services/Services';
// import ProjectDetails from './components/projects/ProjectDetails';
// import StaffProfile from './components/staff/StaffProfile';
// import NotFound from './components/notFound/NotFound';
// import Picture from './components/picture/Picture';
// import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
// import Terms from './components/terms/Terms';
import Loading from './components/loading/Loading';
import ErrorScreen from './components/loading/ErrorScreen';
import { logo } from './components/assets/assets';
// import DeleteAccount from './components/deleteAccount/DeleteAccount';


const Home = lazy(() => import('./components/home/Home'));
const About = lazy(() => import('./components/about/About'));
const Projects = lazy(() => import('./components/projects/Projects'));
const Staff = lazy(() => import('./components/staff/Staff'));
const Contact = lazy(() => import('./components/contact/Contact'));
const Services = lazy(() => import('./components/services/Services'));
const ProjectDetails = lazy(() => import('./components/projects/ProjectDetails'));
const StaffProfile = lazy(() => import('./components/staff/StaffProfile'));
const NotFound = lazy(() => import('./components/notFound/NotFound'));
const PrivacyPolicy = lazy(() => import('./components/privacyPolicy/PrivacyPolicy'));
const Terms = lazy(() => import('./components/terms/Terms'));
// const Loading = lazy(() => import('./components/loading/Loading'));
// const ErrorScreen = lazy(() => import('./components/loading/ErrorScreen'));
const DeleteAccount = lazy(() => import('./components/deleteAccount/DeleteAccount'));


const firebaseConfig = {
  apiKey: "AIzaSyBnC9vKuhlxgJAAQjO98Ltduek7-o2Ncjs",
  authDomain: "tanzator-web.firebaseapp.com",
  projectId: "tanzator-web",
  storageBucket: "tanzator-web.appspot.com",
  messagingSenderId: "28817366846",
  appId: "1:28817366846:web:06d317636ce3762452166f",
  measurementId: "G-2ZSQ67JG5K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);
logEvent(analytics, 'page_view');

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const App = () => {
  const dispatch = useDispatch();

  const [isSlow] = useState(false);
  // const [isSlow, setIsSlow] = useState(false);

  // const [user, setUser] = useState(null);
  // const myProfile = useSelector(state => state.auth.myProfile);
  const { loading, pictures, error } = useSelector(state => state.launch);

  // useEffect(() => {
  //   const unregisterAuthObserver = onAuthStateChanged(auth, user => {
  //     setUser(user ? true : false);
  //   });
  //   return () => unregisterAuthObserver(); // cleanup subscription on unmount

  // }, [dispatch]);


  // Effect to track slow network and set timeout
  // useEffect(() => {
  //   // Set timeout for detecting slow network (e.g., 3 seconds)
  //   const timer = setTimeout(() => {
  //     setIsSlow(true);
  //   }, 3000); // Timeout after 3 seconds

  //   // Clear the timeout if the component loads before the timeout
  //   return () => clearTimeout(timer);
  // }, []);

  useLayoutEffect(() => {
    dispatch(fetchPictures());
  }, [dispatch]);

  if (loading) return <Loading />;

  if (error) return <ErrorScreen />;

  const HomeComponent = () => (
    <Topbars>
      <Home />
      <Footer />
    </Topbars>
  );
  const ServicesComponent = () => (
    <Topbars>
      <Services />
      <Footer />
    </Topbars>
  );
  const AboutComponent = () => (
    <Topbars>
      <About />
      <Footer />
    </Topbars>
  );
  const ProjectsComponent = () => (
    <Topbars>
      <Projects />
      <Footer />
    </Topbars>
  );
  const ProjectDetailsComponent = () => (
    <Topbars>
      <ProjectDetails />
      <Footer />
    </Topbars>
  );
  const StaffComponent = () => (
    <Topbars>
      <Staff />
      <Footer />
    </Topbars>
  );
  const StaffProfileComponent = () => (
    <Topbars>
      <StaffProfile />
      <Footer />
    </Topbars>
  );
  const ContactComponent = () => (
    <Topbars>
      <Contact />
      <Footer />
    </Topbars>
  );
  const TermsComponent = () => (
    <Topbars>
      <Terms />
      <Footer />
    </Topbars>
  );
  const PrivacyPolicyComponent = () => (
    <Topbars>
      <PrivacyPolicy />
      <Footer />
    </Topbars>
  );
  const DeleteAccountComponent = () => (
    <Topbars>
      <DeleteAccount />
      <Footer />
    </Topbars>
  );
  const NotFoundComponent = () => (
    <Topbars>
      <NotFound />
      <Footer />
    </Topbars>
  );
  const SlowLoadingComponent = () => (
    <Topbars>
      <div className="slow-loading-message">
        <p>It's taking longer than expected to load the content. Please check your internet connection, try again or wait a bit longer!</p>
      </div>
      <Footer />
    </Topbars>
  );
  const CenterPlaceholderComponent = () => (
    <Topbars>
      <div className='center-placeholder'>
        <img src={logo} alt='logo' />
        <div className="center-loading-animation">
        </div>
      </div>
      <Footer />
    </Topbars>
  );


  const renderAccess = () => {

    return (
      <div className="mainContainer">
        <Suspense fallback={
          isSlow ? (
            <SlowLoadingComponent />
          ) : (
            <CenterPlaceholderComponent />
          )
        }>
          <Routes>
            <Route path='/' element={<HomeComponent />} />
            <Route path='/what-we-do' element={<ServicesComponent />} />
            <Route path='/about' element={<AboutComponent />} />
            <Route path='/projects' element={<ProjectsComponent />} />
            <Route path='/projects/:projectId' element={<ProjectDetailsComponent />} />
            <Route path='/staff' element={<StaffComponent />} />
            <Route path='/staff/:staffId' element={<StaffProfileComponent />} />
            <Route path='/contact' element={<ContactComponent />} />
            {/* <Route path="/picture/:name" element={<Picture />} /> */}
            <Route path="/terms-and-conditions/:productName/:language" element={<TermsComponent />} />
            <Route path="/privacy-policy/:productName/:language" element={<PrivacyPolicyComponent />} />
            <Route path="/delete-account/:productName" element={<DeleteAccountComponent />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
        </Suspense>
      </div>
    );
  };

  return (
    <BrowserRouter>
      {
        !loading
        &&
        pictures
        &&
        renderAccess()
      }
    </BrowserRouter>
  );
};

export default App;
