import React from 'react';
import { Button } from 'antd'; // Ant Design for buttons
import './ErrorScreen.css'; // Custom CSS for error screen
import errorIcon from '../../assets/logo.png'; // Your error icon path

const ErrorScreen = ({ error }) => {
  const handleRefresh = () => {
    window.location.reload(); // Force page refresh
  };

  return (
    <div className="error-container">
      <div className="error-content">
        <img src={errorIcon} alt="Error" className="error-icon" />
        <h1>Oops! Something went wrong</h1>
        <p>{error}</p>
        <Button type="primary" onClick={handleRefresh}>
          Refresh Page
        </Button>
      </div>
    </div>
  );
};

export default ErrorScreen;
