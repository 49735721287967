import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaWhatsapp } from 'react-icons/fa';
// import ProgressiveImage from '../common/ProgressiveImage';
// import { useSelector } from 'react-redux';
import { Image } from 'antd';
import { logo } from '../assets/assets';

const Footer = () => {
    // const pictures = useSelector(state => state.launch.pictures);

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-row">
                    <div className="footer-logo">
                        <Image
                            src={logo}
                            // src={pictures?.company?.logo ? pictures?.company?.logo : logo}
                            alt="Tanzator Logo"
                            preview={false}
                        /> {/* Replace with actual logo path */}
                    </div>
                    <div className="footer-links">
                        <a href="/about">About Us</a>
                        <a href="/what-we-do">Services</a>
                        <a href="/projects">Projects</a>
                        {/* <a href="/staff">Our Team</a> */}
                        <a href="/contact">Contact Us</a>
                    </div>
                </div>
                <div className="footer-row footer-social">
                    <a href="https://wa.me/message/D5EFQCRI52JJF1" aria-label="WhatsApp" target="_blank" rel="noreferrer"><FaWhatsapp /></a>
                    <a href="https://www.facebook.com/tanzatorinvestments" aria-label="Facebook" target='_blank' rel='noreferrer'><FaFacebookF /></a>
                    <a href="https://www.twitter.com/tanzator" aria-label="Twitter" target='_blank' rel='noreferrer'><FaTwitter /></a>
                    <a href="https://www.linkedin.com/company/tanzator" aria-label="LinkedIn" target='_blank' rel='noreferrer'><FaLinkedinIn /></a>
                    <a href="https://www.instagram.com/tanzatorinvestments" aria-label="Instagram" target='_blank' rel='noreferrer'><FaInstagram /></a>
                </div>
                <div className="footer-row footer-map">
                    <iframe
                        title="Tanzator Location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253568.33077612525!2d39.089472767127056!3d-6.769222473736852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4bae169bd6f1%3A0x940f6b26a086a1dd!2sDar%20es%20Salaam%2C%20Tanzania!5e0!3m2!1sen!2sus!4v1718935083371!5m2!1sen!2sus"
                        // width="600"
                        // height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        frameBorder="0"
                        aria-hidden="false"
                        tabIndex="0"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Tanzator. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
